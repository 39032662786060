import * as $ from 'jquery';

export type TRequestType = "GET" | "PUT" | "POST" | "DELETE";

export default class Network {
    public async sendRequest(url: string, type: TRequestType, headers: any, data: any) {

        if (type === "GET") {
            if (headers === "") {
                headers = {};
            }
            return await $.ajax({
                url,
                type,
                headers
            });
        }

        return await $.ajax({
            url,
            type,
            headers,
            data: JSON.stringify(data),
            processData: false,
            contentType: "application/json",
        });
    }
}