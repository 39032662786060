import React, { useRef, useState } from 'react';
import './signin.css';
import './dashboard.css';
import './player.css';
import './events.css';

import TextField from '@material-ui/core/TextField/TextField';
import Button from '@material-ui/core/Button/Button';
import Network from './libraries/network';

import { format, parseISO } from 'date-fns';
import ReactPlayer from 'react-player';

export default function Signin() {

    const [ getView, setView ] = useState("signin");

    const [ getEmail, setEmail ] = useState("");
    const [ getAccessKey, setAccessKey ] = useState("");

    const [ signinError, setSigninError ] = useState("");

    const [ getUserEvents, setUserEvents ] = useState([]);

    // Show stream
    const [ getStreamURL, setStreamURL ] = useState("");
    const [ getFileType, setFileType ] = useState("");

    const localStorageKeyId = "me-brooksplace-id";

    const url = "https://6p8bzo4ct4.execute-api.us-east-1.amazonaws.com";

    async function processSignin() {
        const apiUrl = url + "/authenticate";

        // Ajax request login
        const result = await new Network().sendRequest(apiUrl, "POST", "", {
            id: "e442839e-7a62-11eb-acfc-0a4283784c69",
            email: getEmail,
            accessKey: getAccessKey
        });
        // Invalid credentials
        if (result.status !== 200) {
            setSigninError("Invalid Credentials");
        } else {
            localStorage.setItem(localStorageKeyId, result.session.sessionId);
            console.log(result.data);
            setUserEvents(result.data);
            setView("event-list");
        }
    }

    async function showEventStream(companyId: string, eventId: string) {
        const apiUrl = url + "/stream" + "?companyid=" + companyId + "&" + "eventid=" + eventId + "&" + "email=" + getEmail;
        // Ajax request login
        try {
            const result = await new Network().sendRequest(apiUrl, "GET", "", "");
            if (result.length === 1) {
                const streamUrl = result[0].eventStreamURL;
                const fileType = result[0].eventStreamMediaType;
                console.log(streamUrl, fileType);
                setStreamURL(streamUrl);
                setFileType(fileType);
                setView("event-stream");
            }
        } catch (e) {
            console.log(e);
        }
    }

    async function performSignOut() {
        const apiUrl = url + "/signout";

        // Ajax request login
        await new Network().sendRequest(apiUrl, "POST", "", {
            id: "e442839e-7a62-11eb-acfc-0a4283784c69",
            sessionId: localStorage.getItem(localStorageKeyId),
        });
        localStorage.removeItem(localStorageKeyId);
        setView("signin");
    }

    async function backToEvents() {
        setView("event-list");
    }

    const postSigninHeader = (
        <div className="site-header-top">
            <div className="header-nav-bar">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-12 col-xl-3">
                            <div className="header-logo">
                                <a href="#" title="" rel="home">
                                    <a href="https://wish.org/oki" target="_blank"><img src="img/MAW_OhioKentuckyAndIndiana_REV.png" alt="" /></a>
                                </a> 
                            </div>
                        </div>
                    </div>
                    <div className="options">
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"

                            className="signout-button"
                            onClick={performSignOut}
                        >
                            Sign Out
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );

    const postSigninHeaderEventStream = (
        <div className="site-header-top">
            <div className="header-nav-bar">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-12 col-xl-3">
                            <div className="header-logo">
                                <a href="#" title="" rel="home">
                                    <a href="https://wish.org/oki" target="_blank"><img src="img/MAW_OhioKentuckyAndIndiana_REV.png" alt="" /></a>
                                </a> 
                            </div>
                        </div>
                    </div>
                    <div className="options">
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className="signout-button"
                            onClick={backToEvents}
                        >
                            Back to Events List
                        </Button>
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className="signout-button"
                            onClick={performSignOut}
                        >
                            Sign Out
                        </Button>
                        
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="secondary"
                            onClick={() => window.open('https://one.bidpal.net/cohgala/browse/donation(details:item/1)', '_blank')}
                        >
                            Donate 
                        </Button>

                    </div>
                </div>
            </div>
        </div>
    );

    const footer = (
        <div className="footer-bottom">
            <div className="container">
                <p>Make-A-Wish® Ohio, Kentucky & Indiana - 2545 Farmers Drive Suite 300 Columbus, OH 43235 - Toll Free (877) 206-9474</p>
                <p>Copyright &copy; 2021</p>
            </div>
        </div>
    );

    switch(getView) {
        case "event-list": {
            return (
                <div className="container event-list">
                    {postSigninHeader}
                    <div className="site-body">
                        <div className="site-body-content">
                            <div className="events-header-text">
                                AVAILABLE EVENTS
                            </div>
                            {
                                getUserEvents.map((item: any) => {
                                    const companyId = item.companyId;
                                    const companyName = item.companyName;
                                    const eventId = item.eventId;
                                    const eventName = item.eventName;
                                    const eventStartDateTime = format(parseISO(item.eventStartDate), 'iiii, MMMM d, yyyy');
                                    // const eventEndDateTime = format(parseISO(item.eventEndDate), "dd-MMM-yy p");

                                    let eventLogo = (<></>);
                                    if (eventName === "Hope Is Essential Gala - Ft. Wayne, IN") {
                                        eventLogo = (
                                            <div className="event-logo">
                                                <img src="img/oki-event.png" />
                                            </div>
                                        )
                                    }
                                    if (eventName === "Hope Is Essential Gala - Columbus, OH") {
                                        eventLogo = (
                                            <div className="event-logo">
                                                <img src="img/oki-event.png" />
                                            </div>
                                        )
                                    }
                                    if (eventName === "Hope Is Essential Gala - Cleveland, OH") {
                                        eventLogo = (
                                            <div className="event-logo">
                                                <img src="img/oki-event.png" />
                                            </div>
                                        )
                                    }
                                    if (eventName === "Hope Is Essential Gala - Cincinnati, OH") {
                                        eventLogo = (
                                            <div className="event-logo">
                                                <img src="img/oki-event.png" />
                                            </div>
                                        )
                                    }
                                    if (eventName === "Hope Is Essential Gala - Louisville, KY") {
                                        eventLogo = (
                                            <div className="event-logo">
                                                <img src="img/oki-event.png" />
                                            </div>
                                        )
                                    }
									else {
										eventLogo = (
                                            <div className="event-logo">
                                                <img src="img/oki-event.png" />
                                            </div>
										)
									}
                                    return (
                                        <div key={eventId} className="event-item" onClick={() => { showEventStream(companyId, eventId) }}>
                                            {eventLogo}
                                            <div className="event-details">
                                                <div className="company-name">{ companyName }</div>
                                                <div className="event-name">{ eventName }</div>
                                                <div className="event-starttime">{eventStartDateTime}</div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    {footer}
                </div>
            )
        }
        break;

        case "event-stream": {
            return (
                <div className="container event-stream">
                    {postSigninHeaderEventStream}
                    <div className="site-body">
                    	<div className='page-wrapper'>
                    		<div className='row'>
                       			
                        			<div className="banner">
                        				 <a href="https://wish.org/oki" target="_blank"><img src="img/banner.png" alt="" id="sponsors"/></a>
                        			</div>
                        		
                    			
                        			<div className="player">
                         			   <ReactPlayer
                                			className="video-js vjs-default-skin"
                                		//	url={
                                  		//	  [
                                   		//	     {
                                   		//	         src: getStreamURL,
                                    	//		        type: getFileType
                                  		//	      }
                                 		//	   ]
                            			//   }
                            			    url={getStreamURL}
                            			    controls={true}
                            			    // playing={true}
                             			   // muted={true}
                             			   width="100%"
                              			  height="100%"
                              			  wrapper="div"
                           			 />
                       			    </div>
                       			
                                   <div className='donate'>
                                       <Button
                                        type="button"
                                        fullWidth
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => window.open('https://one.bidpal.net/cohgala/browse/donation(details:item/1)', '_blank')}
                                       >
                                           Donate 
                                       </Button>
                                   </div>

                        	</div>
                        </div>
                    </div>
                    {footer}
                </div>
            );
        }

        case "signin":
        default: {
            return (
                <div className="container signin">
                    <div className="header-row">
                        <img src="img/MAW_OhioKentuckyAndIndiana_REV.png" alt="" />
                    </div>
                    <div className="title-text"></div>
                    <div className="">
                        <form className="" noValidate>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoFocus
                                value={getEmail}
                                onChange={(e) => { setEmail(e.target.value); } }
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Access Key"
                                type="password"
                                id="password"
                                value={getAccessKey}
                                onChange={(e) => { setAccessKey(e.target.value); } }
                            />
                            <div className="signin-error">{signinError}</div>
                            <Button
                                type="button"
                                fullWidth
                                variant="contained"
                                color="primary"
                                style={{ backgroundColor: 'transparent' }}
                                className="signin-button"
                                onClick={processSignin}
                            >
                                Submit
                            </Button>
                        </form>
                    </div>
                </div>
            )
        }
        break;
    }
}